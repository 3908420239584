<template>
  <div class="shadow-2xl rounded-3xl w-full mx-auto">
    <div class="intro-y box col-span-2">
      <div class="bg-white p-4 rounded shadow-sm">
        <!-- ADD Staff Button -->
        <div class="mb-4">
          <b-button variant="primary" @click="showAddStaffModal = true">
            ADD Staff +
          </b-button>
        </div>

        <!-- Table to display staffs -->
        <table class="table table-bordered table-hover">
          <thead class="bg-primary text-purple">
            <tr>
              <th class="text-center">SL</th>
              <th class="text-center">Staff</th>
              <!-- <th class="text-center">Action</th> -->
              <!-- New Action Column -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(Staff, index) in staffs"
              :key="index"
              class="bg-gray-100"
            >
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-center">{{ Staff.name }}</td>
              <!-- <td class="text-center">
                <button class="btn btn-danger" @click="deleteStaff(Staff.id)">
                  Delete
                </button>
              </td> -->
            </tr>
          </tbody>
        </table>

        <!-- Add Staff Modal -->
        <b-modal v-model="showAddStaffModal" title="Add New Staff" hide-footer>
          <b-form @submit.prevent="submitStaff">
            <b-form-group label="Staff Name" label-for="name-input">
              <b-form-input
                id="name-input"
                v-model="newStaff.name"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Designation" label-for="designation-input">
              <b-form-input
                id="designation-input"
                v-model="newStaff.designation"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Email" label-for="email-input">
              <b-form-input
                id="email-input"
                type="email"
                v-model="newStaff.email"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Phone" label-for="phone-input">
              <b-form-input
                id="phone-input"
                type="text"
                v-model="newStaff.phone"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Mobile" label-for="mobile-input">
              <b-form-input
                id="mobile-input"
                type="text"
                v-model="newStaff.mobile"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Password" label-for="password-input">
              <b-form-input
                id="password-input"
                type="password"
                v-model="newStaff.password"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Status" label-for="status-input">
              <b-form-select
                id="status-input"
                v-model="newStaff.status"
                required
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </b-form-select>
            </b-form-group>

            <b-form-group label="Image" label-for="image-input">
              <b-form-file
                id="image-input"
                v-model="newStaff.image"
                accept="image/*"
                @change="onFileChange"
              ></b-form-file>
            </b-form-group>

            <b-form-group label="National ID (NID)" label-for="nid-input">
              <b-form-input
                id="nid-input"
                v-model="newStaff.nid"
              ></b-form-input>
            </b-form-group>

            <div class="text-right">
              <b-button variant="secondary" @click="showAddStaffModal = false"
                >Cancel</b-button
              >
              &nbsp;
              <b-button variant="primary" type="submit">Submit</b-button>
            </div>
          </b-form>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BLink,
  BTooltip,
  BMedia,
  BBadge,
  BButton,
  VBToggle,
  BSidebar,
  BForm,
  BFormTextarea,
  BModal,
  BFormFile,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
import Mixin from "@core/services/mixin";
import { GET_STAFFS, ADD_STAFF, DELETE_STAFF } from "@core/services/api";

export default {
  mixins: [Mixin],
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  icon: "EyeIcon",
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BLink,
    BTooltip,
    BMedia,
    BBadge,
    BButton,
    BSidebar,
    BForm,
    BFormTextarea,
    BModal,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
    ToastificationContent,
    BFormFile,
  },
  data() {
    return {
      isp_id: JSON.parse(localStorage.getItem("userData")),
      staffs: [],
      showAddStaffModal: false,
      staff_id: "",
      newStaff: {
        name: "",
        designation: "",
        email: "",
        phone: "",
        mobile: "",
        password: "",
        status: "active", // Assuming "active" as the default status
        image: null, // Image can be `null` or empty string initially
        nid: "",
      },
    };
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0]; // Get the selected file
      if (file) {
        this.newStaff.image = file; // Assign the file to newStaff.image
      }
      console.log(this.newStaff.image);
    },
    fetchStaffs() {
      this.loading = true;
      this.$http
        .get(process.env.VUE_APP_BASEURL + GET_STAFFS, {
          params: {
            isp_id: this.isp_id.id,
          },
        })
        .then((res) => {
          this.staffs = res.data.data.staffs;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitStaff() {
      let formData = new FormData();
      formData.append("isp_id", this.isp_id.id);
      formData.append("name", this.newStaff.name);
      formData.append("designation", this.newStaff.designation);
      formData.append("email", this.newStaff.email);
      formData.append("phone", this.newStaff.phone);
      formData.append("mobile", this.newStaff.mobile);
      formData.append("password", this.newStaff.password);
      formData.append("status", this.newStaff.status);
      if (this.newStaff.image) {
        formData.append("image", this.newStaff.image); // Image file
      }
      formData.append("nid", this.newStaff.nid);

      this.$http
        .post(process.env.VUE_APP_BASEURL + ADD_STAFF, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Staff added successfully!",
              variant: "success",
            },
          });
          this.resetStaffForm();
          this.fetchStaffs();
          this.showAddStaffModal = false;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Failed to add the Staff.",
              message: err.message,
              variant: "danger",
            },
          });
        });
    },
    resetStaffForm() {
      this.newStaff = {
        name: "",
        designation: "",
        email: "",
        phone: "",
        mobile: "",
        password: "",
        status: "active", // Assuming "active" as the default status
        image: null, // Image can be `null` or empty string initially
        nid: "",
      };
    },
    deleteStaff(staffId) {
      if (confirm("Are you sure you want to delete this Staff?")) {
        this.$http
          .post(process.env.VUE_APP_BASEURL + DELETE_STAFF, {
            staff_id: staffId,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Staff deleted successfully!",
                variant: "success",
              },
            });
            // Refetch the staffs list after deletion
            this.fetchStaffs();
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Failed to delete the Staff.",
                message: "Failed to delete the Staff.",
                variant: "danger",
              },
            });
          });
      }
    },
  },
  mounted() {
    this.fetchStaffs();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>
.error-message {
  color: red;
}
</style>
